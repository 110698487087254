import type { MetaDescriptor } from '@remix-run/node';
import type { FaqSchemaFragment } from 'generated/graphql';
import type { Answer, FAQPage, Question } from 'schema-dts';

export const createFAQSchema = (
    schemas?: (FaqSchemaFragment | null)[]
): MetaDescriptor[] => {
    if (!schemas) return [];
    const FAQschema = schemas.reduce((schemas, faq) => {
        if (faq?.Question && faq?.Answer) {
            const answer: Answer = {
                '@type': 'Answer',
                text: faq.Answer,
            };
            const question: Question = {
                '@type': 'Question',
                name: faq.Question,
                acceptedAnswer: answer,
            };
            schemas.push(question);
        }
        return schemas;
    }, [] as Question[]);

    const faqPage: FAQPage = {
        '@type': 'FAQPage',
        name: 'FAQs',
        mainEntity: FAQschema,
    };
    return FAQschema.length > 0 ? [faqPage] : [];
};
